import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import default styles

const DateRangePicker = ({
  start,
  end,
  onChangeStart,
  onChangeEnd,
  checked,
  onCheckChange,
  dash,
  onSearch
}) => {
  return (
    <div className="date-pickers-container">
      <div style={{ marginRight: "10px" }}>
        {!dash &&
          <label
            className="container-event"
            style={{
              margin: 0,
              fontSize: "1rem",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
          >
            <div className="col-paragraphBg" onClick={onCheckChange}>
              {"All"}
            </div>
            <input type="checkbox" checked={checked} onChange={onCheckChange} />
            <span className="checkMark" />
          </label>
        }
        {dash && <label
          className="container-event"
          style={{
            margin: 0,
            fontSize: "1rem",
            paddingTop: "0px",
            paddingBottom: "0px",
          }}
        >
          <div style={{ marginBottom: 5 }}
            className="btn-common user-search mouse-cursor col-white"
            onClick={onSearch}
          >
            {"Search"}
          </div>
        </label>}
      </div>
      <div
        style={{ height: 10, width: 1, backgroundColor: "black", margin: 10 }}
      />
      <p
        style={{
          margin: "0px",
          marginLeft: "10px",
          marginRight: "10px",
          opacity: checked ? 0.3 : 1,
        }}
      >
        From:
      </p>
      <div style={{ opacity: checked ? 0.3 : 1, display: "flex" }}>
        <DatePicker
          disabled={checked}
          selected={start || new Date()}
          onChange={(date) => onChangeStart(date)}
          selectsStart
          startDate={start || new Date()}
          endDate={end || new Date()}
          isClearable
          placeholderText="I have been cleared!"
          // dayClassName={(test123) => "custom-day"}  
        />
      </div>
      <p
        style={{
          margin: "0px",
          marginLeft: "10px",
          marginRight: "10px",
          opacity: checked ? 0.3 : 1,
        }}
      >
        To:
      </p>
      <div style={{ opacity: checked ? 0.3 : 1, display: "flex" }}>
        <DatePicker
          disabled={checked}
          selected={end || new Date()}
          onChange={(date) => onChangeEnd(date)}
          selectsEnd
          startDate={start || new Date()}
          endDate={end || new Date()}
          minDate={start || new Date()}
          isClearable
          placeholderText="I have been cleared!"
          dayClassName={(date) => "custom-day"}  // Add the custom class here
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
