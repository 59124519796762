import React from "react";
import { reset, login } from "../redux/actions/users/registration";
import "../assets/css/login.css";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";
import zingLogo from "../assets/images/zing.svg";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.tmr = null;
    this.state = {
      email: "",
      password: "",
      checkLang: this.props.i18n.language,
    };
  }

  getBackgroundClassName = () => {
    return this.state.checkLang === "he" ? "admin-login-he" : "admin-login-bg";
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.i18n.language !== prevState.checkLang) {
      this.setState({ checkLang: this.props.i18n.language });
    }

    if (
      this.props.msg_registration &&
      prevProps.msg_registration !== this.props.msg_registration
    ) {
      toast(this.props.msg_registration);
      const { reset } = this.props;
      clearTimeout(this.tmr);
      this.tmr = setTimeout(function () {
        reset();
        this.tmr = null;
        window.location.href = "/";
      }, 1000);
      this.setState({
        email: "",
        password: "",
      });
    }
    if (
      this.props.msg_user_error &&
      prevProps.msg_user_error !== this.props.msg_user_error
    ) {
      let msg = this.props.msg_user_error;
      let msg_array = [];
      if (msg.msg_login_email) {
        msg_array.push(msg.msg_login_email);
      }
      if (msg.msg_login_password) {
        msg_array.push(msg.msg_login_password);
      }
      if (msg_array.length === 0) {
        toast(this.props.msg_user_error);
      } else {
        for (let k = 0; k < msg_array.length; k++) {
          toast(msg_array[k]);
        }
      }
      const { reset } = this.props;
      clearTimeout(this.tmr);
      this.tmr = setTimeout(function () {
        reset();
        this.tmr = null;
      }, 1500);
    }
  }

  login = () => {
    const { login } = this.props;
    const data = {
      email: this.state.email,
      password: this.state.password,
    };
    if (login) {
      login(data);
    }
  };

  onChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value || "",
    });
  };

  onInputAlbumId = (code) => {
    if (code === 13) {
      if (document.activeElement.id === "email") {
        document.getElementById("password").focus();
      } else if (document.activeElement.id === "password") {
        this.login();
      }
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div
          className="spinning-curtain"
          style={{ display: this.props.spinning ? "flex" : "none" }}
        >
          <div className="lds-dual-ring" />
        </div>
        <div className={this.getBackgroundClassName()}>
          <ToastContainer />
          <div className="login-body">
            <div>{t("signin")}</div>
            <input
              id="email"
              type="email"
              placeholder={t("email")}
              className="mt-20"
              value={this.state.email}
              onChange={(e) => this.onChange(e)}
              onKeyUp={(e) => this.onInputAlbumId(e.keyCode)}
              required
            />
            <input
              id="password"
              type="password"
              placeholder={t("password")}
              className="mt-20"
              value={this.state.password}
              onChange={(e) => this.onChange(e)}
              onKeyUp={(e) => this.onInputAlbumId(e.keyCode)}
              required
            />
            <div
              className="btn-common txt-16 col-white justify-center mouse-cursor mt-30"
              onClick={this.login}
            >
              {t("login")}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinning: state.users.spinning,
    msg_registration: state.users.msg_registration,
    msg_user_error: state.users.msg_user_error,
  };
};

export default connect(mapStateToProps, {
  reset,
  login,
})(withTranslation("translations")(Login));
