import React, { useState } from "react";
import "../assets/css/header.css";
import exportExcel from "../assets/images/icons8-export-excel-50 (2).png";


const SearchPage = () => {
  const [searchParams, setSearchParams] = useState({
    artist: "",
    album: "",
    albumId: "",
    singerId: "",
    label: "",
  });
  
  // Add a state to track which input is currently active
  const [activeInput, setActiveInput] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Set the active input when user starts typing
    if (value && !activeInput) {
      setActiveInput(name);
    }
    
    // Clear the active input if the field becomes empty
    if (!value) {
      setActiveInput(null);
    }
    
    // Update the search params
    setSearchParams({ ...searchParams, [name]: value });
  };

  const handleSearch = () => {
    console.log("Searching with:", searchParams);
    // Call API or handle search logic
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("Selected file:", file.name);
      // Here you would add the logic to process the Excel file
    }
  };

  return (
    <div className="flex flex-col items-center p-10">
      <div className="card-serch w-full max-w-3xl p-6 shadow-lg">
        <div className="card-content">
          <h2 className="text-2xl font-bold text-darkBlue pb-4">חיפוש</h2>
          <div className="">
            <input
              name="artist"
              placeholder="חיפוש אמן"
              value={searchParams.artist}
              onChange={handleChange}
              className="mt-20"
              disabled={activeInput && activeInput !== "artist"}
            />
            <input
              name="album"
              placeholder="חיפוש אלבום"
              value={searchParams.album}
              onChange={handleChange}
              className="mt-20"
              disabled={activeInput && activeInput !== "album"}
            />
            <input
              name="albumId"
              placeholder="ID אלבום"
              value={searchParams.albumId}
              onChange={handleChange}
              className="mt-20"
              disabled={activeInput && activeInput !== "albumId"}
            />
            <input
              name="singerId"
              placeholder="ID זמר"
              value={searchParams.singerId}
              onChange={handleChange}
              className="mt-20"
              disabled={activeInput && activeInput !== "singerId"}
            />
            <input
              name="label"
              placeholder="חיפוש לפי Label"
              value={searchParams.label}
              onChange={handleChange}
              className="mt-20"
              disabled={activeInput && activeInput !== "label"}
            />
          </div>
           
          <div className="flex justify-end mt-20">
            <button onClick={handleSearch} className="btn-common-search">
              <search size={16} /> חיפוש
            </button>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default SearchPage;