import React, { Component } from "react";
import { connect } from "react-redux";
import "../assets/css/dashboard.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  reset,
  getAlbumListFromSite,
  addAlbumFromSite,
  getAlbumByIdFromSite,
  addTrackFromSite,
  getAlbumByRangeIdFromSite,
} from "../redux/actions/users/get-data-site";
import DeleteAlbum from "./modal-delete-album";
import { withTranslation } from "react-i18next";
import bin28 from "../assets/images/bin-28.svg";
import addIcon from "../assets/images/add.svg";
import maxIcon from "../assets/images/maximize.svg";
import closeIcon from "../assets/images/close.svg";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";

class AddAlbum extends Component {
  constructor(props) {
    super(props);
    this.tmr = null;
    this.state = {
      album_list: [],
      new_checked: [],
      show: false,
      allChecked: false,
      delete_album_id: "",
      action_flag: true,
      album_id: "",
      from_album_id: "",
      to_album_id: "",
      flag_input: false,
      flag_range: false,
      get_album: null,
      get_albums: [],
      current_page: 1,
      page_neighbours: 3,
      pagination: 10,
      page_num: "",
      pageArray: [1, 2, 3, 4],
      total_page: 100,
      openImage: false,
      deletionMode: null,
    };

    this.onCheckBox = this.onCheckBox.bind(this);
    this.onAllCheck = this.onAllCheck.bind(this);
    this.onActionClick = this.onActionClick.bind(this);
  }
  componentDidMount() {
    this.onInitial();
    this.getDataFromSite();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.get_album_list &&
      this.props.get_album_list !== prevProps.get_album_list
    ) {
      this.setState({
        album_list: this.props.get_album_list,
      });
    }
    if (
      this.props.msg_album_list_error &&
      this.props.msg_album_list_error !== prevProps.msg_album_list_error
    ) {
      toast(this.props.msg_album_list_error);
      const { reset } = this.props;
      clearTimeout(this.tmr);
      this.tmr = setTimeout(function () {
        reset();
        this.tmr = null;
      }, 2000);
    }
    if (
      this.state.current_page !== prevState.current_page ||
      this.state.pagination !== prevState.pagination
    ) {
      if (!this.state.flag_input) {
        this.getDataFromSite();
      }
    }
    if (
      this.props.msg_create_album &&
      this.props.msg_create_album !== prevProps.msg_create_album
    ) {
      toast(this.props.msg_create_album);
      const { reset } = this.props;
      clearTimeout(this.tmr);
      this.tmr = setTimeout(function () {
        reset();
        this.tmr = null;
      }, 2000);
      if (!this.state.flag_input) {
        this.getDataFromSite();
      } else if (!this.state.flag_range) {
        this.getByAlbumId();
      } else {
        this.getByRangeAlbumId();
      }
    }
    // Check all
    if (this.state.allChecked !== prevState.allChecked) {
      const temp = [];
      if (this.state.allChecked === true) {
        if (this.state.flag_input === false) {
          let list = this.state.album_list;
          Object.keys(list).map((item, key) => {
            if (!this.state.action_flag && list[key].state === 1) {
              temp.push(list[key].id.toString());
            } else if (this.state.action_flag && list[key].state !== 1) {
              temp.push(list[key].id.toString());
            }
            return null;
          });
        } else {
          if (this.state.flag_range == false) {
            let list = this.state.get_album && this.state.get_album.tracks;
            Object.keys(list).map((item, key) => {
              if (!this.state.action_flag && list[key].state === 1) {
                temp.push(list[key].id.toString());
              } else if (this.state.action_flag && list[key].state !== 1) {
                temp.push(list[key].id.toString());
              }
              return null;
            });
          } else {
            let list = this.state.get_albums;
            Object.keys(list).map((item, key) => {
              if (!this.state.action_flag && list[key].state === 1) {
                temp.push(list[key].id.toString());
              } else if (this.state.action_flag && list[key].state !== 1) {
                temp.push(list[key].id.toString());
              }
              return null;
            });
          }
        }
        this.setState({
          new_checked: temp,
        });
      }
    }
    if (this.state.new_checked !== prevState.new_checked) {
      let temp = [];
      if (this.state.flag_input === false) {
        let list = this.state.album_list;
        Object.keys(list).map((item, key) => {
          if (!this.state.action_flag && list[key].state === 1) {
            temp.push(list[key].id.toString());
          } else if (this.state.action_flag && list[key].state !== 1) {
            temp.push(list[key].id.toString());
          }
          return null;
        });
      } else if (this.state.flag_range === false) {
        let list = this.state.get_album && this.state.get_album.tracks;
        Object.keys(list).map((item, key) => {
          if (!this.state.action_flag && list[key].state === 1) {
            temp.push(list[key].id.toString());
          } else if (this.state.action_flag && list[key].state !== 1) {
            temp.push(list[key].id.toString());
          }
          return null;
        });
      } else {
        let list = this.state.get_albums;
        Object.keys(list).map((item, key) => {
          if (!this.state.action_flag && list[key].state === 1) {
            temp.push(list[key].id.toString());
          } else if (this.state.action_flag && list[key].state !== 1) {
            temp.push(list[key].id.toString());
          }
          return null;
        });
      }

      if (
        this.state.new_checked.length > 0 &&
        this.state.new_checked.length === temp.length
      ) {
        this.setState({
          allChecked: true,
        });
      } else {
        this.setState({
          allChecked: false,
        });
      }
    }
    /**
     * Getting data from one album id
     */
    if (
      this.props.msg_album_byId &&
      this.props.msg_album_byId !== prevProps.msg_album_byId
    ) {
      toast(this.props.msg_album_byId);
      const { reset } = this.props;
      clearTimeout(this.tmr);
      this.tmr = setTimeout(function () {
        reset();
        this.tmr = null;
      }, 2000);
    }
    if (
      this.props.get_album_byId &&
      this.props.get_album_byId !== prevProps.get_album_byId
    ) {
      this.setState({
        get_album: this.props.get_album_byId,
        get_albums: [],
        from_album_id: "",
        to_album_id: "",
      });
    }
    if (
      this.props.get_album_byRangeId &&
      this.props.get_album_byRangeId !== prevProps.get_album_byRangeId
    ) {
      this.setState({
        get_albums: this.props.get_album_byRangeId,
        get_album: "",
        album_id: "",
      });
    }
    // if (this.state.flag_input) {
    //   if (!this.state.flag_range) {
    //     this.getByAlbumId();
    //   } else {
    //     this.getByRangeAlbumId();
    //   }
    // }
  }

  getDataFromSite = async () => {
    const { getAlbumListFromSite } = this.props;
    if (getAlbumListFromSite) {
      const data = {
        role_id: localStorage.id,
        pagination: this.state.pagination,
        current_page: this.state.current_page,
      };
      getAlbumListFromSite(data);
    }
  };

  onCount = (e) => {
    this.setState({
      pagination: e.target.value,
      allChecked: false,
      new_checked: [],
    });
  };
  onPageClick = (item) => {
    this.setState({
      current_page: item,
      action_flag: true,
      new_checked: [],
      allChecked: false,
    });
    this.onCalcPageArray(item);
  };
  onCalcPageArray = (item) => {
    const current_page = item;
    const page_neighbours = this.state.page_neighbours;
    const total_page = this.state.total_page;
    //const total_page = Math.ceil(total_list_count / pagination);
    const start_page = Math.max(1, current_page - page_neighbours);
    const end_page = Math.min(total_page, current_page + page_neighbours);
    let pageArray = [];
    for (let k = start_page; k <= end_page; k++) {
      pageArray.push(k);
    }
    this.setState({
      pageArray: pageArray,
    });
  };
  onInitial = () => {
    this.setState({
      album_list: [],
      new_checked: [],
      show: false,
      allChecked: false,
      delete_album_id: "",
      action_flag: true,
      album_id: "",
      from_album_id: "",
      to_album_id: "",
      flag_input: false,
      flag_range: false,
      get_album: "",
      get_albums: [],
      current_page: 1,
      page_neighbours: 3,
      pagination: 10,
      page_num: "",
      pageArray: [1, 2, 3, 4],
      total_page: 100,
    });
  };

  onAddAlbum = (addMode) => {
    const { addAlbumFromSite, addTrackFromSite } = this.props;

    // When not in track-only mode, do album-level action.
    // This branch will run in:
    // - Album list mode (flag_input false)
    // - Album range mode (flag_range true)
    // - Single album mode (flag_input true AND get_album exists)
    if (
      !this.state.flag_input ||
      this.state.flag_range ||
      (this.state.flag_input && addMode === "album")
    ) {
      const albumIds =
        this.state.flag_input && !this.state.flag_range
          ? [this.state.get_album?.id?.toString()].filter(Boolean)
          : this.state.new_checked;

      if (albumIds.length > 0 && addAlbumFromSite) {
        const data = {
          role_id: localStorage.id,
          id: albumIds,
          lang: this.props.i18n.language || "en",
        };
        addAlbumFromSite(data);
      }
    } else {
      // Otherwise, do track-level action (this branch now won’t run for the top button)
      if (addTrackFromSite) {
        let tracks = this.state.get_album.tracks;
        let new_checked = this.state.new_checked;
        let send_tracks = [];
        for (let k = 0; k < new_checked.length; k++) {
          let selectedTrack = tracks.find(
            (track) => track.id.toString() === new_checked[k].toString()
          );
          if (selectedTrack) {
            send_tracks.push({
              download: selectedTrack.download || 0,
              name:
                this.props.i18n.language === "en"
                  ? selectedTrack.enName
                  : selectedTrack.heName,
              enName: selectedTrack.enName,
              heName: selectedTrack.heName,
              url: selectedTrack.file || selectedTrack.url,
              id: selectedTrack.id.toString(),
              lang: this.props.i18n.language || "en",
            });
          }
        }
        if (send_tracks.length > 0) {
          const data = {
            role_id: localStorage.id,
            album: this.state.get_album,
            tracks_ids: new_checked,
            tracks: send_tracks,
            lang: this.props.i18n.language || "en",
          };
          addTrackFromSite(data);
        }
      }
    }
    this.setState({
      new_checked: [],
      allChecked: false,
    });
  };

  onShowDeleteModal = (deletionMode = "album") => {
    this.setState({
      show: true,
      delete_album_id: this.state.new_checked,
      deletionMode,
    });
  };

  hideDeleteModal = () => {
    this.setState({
      show: false,
      new_checked: [],
      allChecked: false,
    });
  };

  /**
   * For registration of albums or tracks
   */
  onCheckBox = (e) => {
    const { new_checked } = this.state;
    const temp = JSON.parse(JSON.stringify(new_checked));
    if (e.target.checked === true) {
      temp.push(e.target.id);
    } else {
      temp.splice(temp.indexOf(e.target.id), 1);
    }
    this.setState({ new_checked: temp });
  };
  onActionClick = () => {
    this.setState({
      action_flag: !this.state.action_flag,
      new_checked: [],
      allChecked: false,
    });
  };

  onAllCheck = () => {
    const { flag_input, flag_range, get_album, get_albums, action_flag } =
      this.state;

    let temp = [];
    if (!flag_input) {
      // Handle album list selection
      let list = this.state.album_list;
      Object.keys(list).map((item, key) => {
        if (!action_flag && list[key].state === 1) {
          temp.push(list[key].id.toString());
        } else if (action_flag && list[key].state !== 1) {
          temp.push(list[key].id.toString());
        }
        return null;
      });
    } else if (!flag_range) {
      // ✅ Handle track selection when album is selected
      let list = get_album && get_album.tracks;
      Object.keys(list).map((item, key) => {
        if (!action_flag && list[key].state === 1) {
          temp.push(list[key].id.toString()); // Push by ID
        } else if (action_flag && list[key].state !== 1) {
          temp.push(list[key].id.toString());
        }
        return null;
      });
    } else {
      // Handle range of albums
      let list = get_albums;
      Object.keys(list).map((item, key) => {
        if (!action_flag && list[key].state === 1) {
          temp.push(list[key].id.toString());
        } else if (action_flag && list[key].state !== 1) {
          temp.push(list[key].id.toString());
        }
        return null;
      });
    }

    this.setState({
      allChecked: !this.state.allChecked,
      new_checked: this.state.allChecked ? [] : temp,
    });
  };

  onAlbumId = (e) => {
    this.setState({
      [e.target.id]: e.target.value || "",
    });
  };
  onInputAlbumId = (code) => {
    if (code === 13) {
      if (this.state.album_id !== "") {
        this.setState({
          flag_input: true,
          flag_range: false,
        });
        this.getByAlbumId();
      } else {
        this.setState({
          flag_input: false,
          flag_range: false,
          get_album: "",
        });
      }
      this.setState({
        new_checked: [],
        allChecked: false,
      });
    }
  };
  onInputToAlbumId = (code) => {
    if (code === 13) {
      if (this.state.from_album_id !== "" && this.state.to_album_id !== "") {
        this.setState({
          flag_input: true,
          flag_range: true,
        });
        this.getByRangeAlbumId();
      } else {
        this.setState({
          flag_input: false,
          flag_range: false,
          get_albums: "",
        });
      }
      this.setState({
        new_checked: [],
        allChecked: false,
      });
    }
  };

  /**
   * calling by album_id
   */
  getByAlbumId = () => {
    const { getAlbumByIdFromSite } = this.props;
    if (getAlbumByIdFromSite) {
      const data = {
        role_id: localStorage.id,
        id: this.state.album_id,
      };
      getAlbumByIdFromSite(data); // Now uses GraphQL
    }
  };

  getByRangeAlbumId = () => {
    const { getAlbumByRangeIdFromSite } = this.props;
    if (getAlbumByRangeIdFromSite) {
      const data = {
        from_id: this.state.from_album_id,
        to_id: this.state.to_album_id,
        role_id: localStorage.id,
      };
      getAlbumByRangeIdFromSite(data); // Now uses GraphQL
    }
  };

  onBack = () => {
    this.setState({
      flag_input: false,
      flag_range: false,
    });
  };

  getLocalizedName = (item) => {
    return item[(this.props.i18n.language || "en") + "Name"];
  };

  allTracksRegistered = () => {
    const { get_album } = this.state;
    if (!get_album) return false;
    return get_album.tracks.every((track) => track.state === 1);
  };

  render() {
    const { t } = this.props;
    const { album_list } = this.state;

    const paginationArray = [10, 15, 25, 50];
    return (
      <>
        <div
          className="spinning-curtain"
          style={{ display: this.props.spinning ? "flex" : "none" }}
        >
          <div className="lds-dual-ring" />
        </div>
        <ToastContainer />
        <div className="col-darkBlue txt-30 txt-bold pb-20 pl-40">
          {this.state.flag_input ? t("track_list") : t("album_list")}
        </div>
        <div className="form-bg">
          <div className="flex-space album-list">
            <div className="justify-left add-album">
              <div className="justify-left">
                {!this.state.flag_input && (
                  <div className="justify-left">
                    <div className="col-buttonAndLink">{t("counts")}</div>
                    <div style={{ display: "flex" }}>
                      <select
                        className="count mouse-cursor"
                        onChange={this.onCount}
                      >
                        {paginationArray.map((item, key) => {
                          return (
                            <option key={key} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}
                <div className="justify-left">
                  <div
                    className="col-buttonAndLink"
                    style={{ marginRight: 10 }}
                  >
                    {t("album_id")}
                  </div>
                  <div className="mr-id-11">
                    <input
                      id="album_id"
                      className="count mouse-cursor"
                      placeholder={t("album_id_registration")}
                      value={this.state.album_id}
                      onChange={(e) => this.onAlbumId(e)}
                      onKeyUp={(e) => this.onInputAlbumId(e.keyCode)}
                    />
                  </div>
                </div>
              </div>
              <div className="justify-left">
                <div className="justify-left">
                  <div
                    className="col-buttonAndLink"
                    style={{ marginRight: 10 }}
                  >
                    {t("from") + " " + t("album_id")}
                  </div>
                  <div className="mr-id-11">
                    <input
                      id="from_album_id"
                      className="count mouse-cursor"
                      placeholder={t("album_id_registration")}
                      value={this.state.from_album_id}
                      onChange={(e) => this.onAlbumId(e)}
                      // onKeyUp={e => this.onInputAlbumId(e.keyCode)}
                    />
                  </div>
                </div>
                <div className="justify-left">
                  <div
                    className="col-buttonAndLink"
                    style={{ marginRight: 10 }}
                  >
                    {t("to") + " " + t("album_id")}
                  </div>
                  <div className="mr-id-11">
                    <input
                      id="to_album_id"
                      className="count mouse-cursor"
                      placeholder={t("album_id_registration")}
                      value={this.state.to_album_id}
                      onChange={(e) => this.onAlbumId(e)}
                      onKeyUp={(e) => this.onInputToAlbumId(e.keyCode)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.state.flag_input && (
              <div
                className="btn-common action mouse-cursor col-white mr-id-11 mr-10"
                onClick={this.onBack}
                style={{ width: "100px" }}
              >
                {t("back")}
              </div>
            )}
            {this.state.flag_input && this.state.get_album ? (
              this.state.get_album.state !== 1 ? (
                <div
                  style={{ width: "100px" }}
                  className="btn-common action mouse-cursor col-white mr-id-11 mr-10"
                  onClick={() => this.onAddAlbum("album")}
                >
                  {t("register")}
                </div>
              ) : (
                <div
                  style={{ width: "100px" }}
                  className="btn-common action mouse-cursor col-white mr-10 mr-10"
                  onClick={() => {
                    this.setState(
                      {
                        new_checked: [
                          this.state.get_album?.id?.toString(),
                        ].filter(Boolean),
                      },
                      () => {
                        this.onShowDeleteModal();
                      }
                    );
                  }}
                >
                  {t("unregister")}
                </div>
              )
            ) : this.state.action_flag ? (
              <div
                className="btn-common action mouse-cursor col-white"
                onClick={() => this.onAddAlbum("album")}
              >
                {t("register")}
              </div>
            ) : (
              <div
                className="btn-common action mouse-cursor col-white"
                onClick={this.onShowDeleteModal}
              >
                {t("unregister")}
              </div>
            )}
          </div>
          {!this.state.flag_input ? (
            <>
              <div className="table-p">
                <table className="tList">
                  <thead>
                    <tr className="table-list">
                      <th>{t("no")}</th>
                      <th>{t("id")}</th>
                      <th>{t("name")}</th>
                      <th>{t("date")}</th>
                      <th>{t("image")}</th>
                      <th>{t("state")}</th>
                      <th className="mouse-cursor">
                        {this.state.action_flag ? (
                          <div
                            className="justify-left"
                            style={{ paddingTop: 13 }}
                          >
                            <label className="container-event justify-left">
                              <div
                                className="col-paragraphBg"
                                onClick={this.onActionClick}
                              >
                                {t("register")}
                              </div>
                              <input
                                type="checkbox"
                                checked={this.state.allChecked}
                                onChange={this.onAllCheck}
                              />
                              <span className="checkMark" />
                            </label>
                          </div>
                        ) : (
                          <div
                            className="justify-left"
                            style={{ paddingTop: 13 }}
                          >
                            <label className="container-event justify-left">
                              <div
                                className="col-paragraphBg"
                                onClick={this.onActionClick}
                              >
                                {t("unregister")}
                              </div>
                              <input
                                type="checkbox"
                                checked={this.state.allChecked}
                                onChange={this.onAllCheck}
                              />
                              <span className="checkMark" />
                            </label>
                          </div>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.album_list &&
                      Object.keys(this.state.album_list).map((item, key) => {
                        return (
                          <tr key={key}>
                            <td style={{ paddingLeft: 20 }}>{key + 1}</td>
                            <td>{album_list[key].id}</td>
                            <td>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: this.getLocalizedName(
                                    album_list[key]
                                  ),
                                }}
                              />
                            </td>
                            <td>
                              {new Date(
                                album_list[key].releasedAt
                              ).toLocaleString([], {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </td>
                            <td>
                              {album_list[key].images.small && (
                                <img
                                  className="thumbnail-size"
                                  src={album_list[key].images.small}
                                  alt=""
                                />
                              )}
                            </td>
                            <td className="flex-common">
                              {album_list[key].state === 1 ? (
                                <div
                                  className="icon-flex tooltip"
                                  onClick={() => {
                                    this.setState(
                                      {
                                        new_checked: [album_list[key].id],
                                      },
                                      () => {
                                        this.onShowDeleteModal();
                                      }
                                    );
                                  }}
                                >
                                  <img
                                    className="icon-size"
                                    src={bin28}
                                    alt="View"
                                  />
                                  <span className="tooltiptext">
                                    {t("registered_album")}
                                  </span>
                                </div>
                              ) : (
                                <div
                                  className="icon-flex tooltip"
                                  onClick={() => {
                                    this.setState(
                                      {
                                        new_checked: [album_list[key].id],
                                      },
                                      () => {
                                        this.onAddAlbum();
                                      }
                                    );
                                  }}
                                >
                                  <img
                                    className="icon-size"
                                    src={addIcon}
                                    alt="Edit"
                                  />
                                  <span className="tooltiptext">
                                    {t("add_album")}
                                  </span>
                                </div>
                              )}
                            </td>
                            <td>
                              {!this.state.action_flag &&
                                album_list[key].state === 1 && (
                                  <label className="container-event" key={key}>
                                    <input
                                      id={album_list[key].id}
                                      type="checkbox"
                                      checked={this.state.new_checked.includes(
                                        album_list[key].id.toString()
                                      )}
                                      onChange={(e) => this.onCheckBox(e)}
                                    />
                                    <span className="checkMark" />
                                  </label>
                                )}
                              {this.state.action_flag &&
                                album_list[key].state !== 1 && (
                                  <label className="container-event" key={key}>
                                    <input
                                      id={album_list[key].id}
                                      type="checkbox"
                                      checked={this.state.new_checked.includes(
                                        album_list[key].id.toString()
                                      )}
                                      onChange={(e) => this.onCheckBox(e)}
                                    />
                                    <span className="checkMark" />
                                  </label>
                                )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="help-center-align">
                <div
                  className="product-btn justify-center"
                  onClick={() => this.onPageClick(1)}
                >
                  <svg
                    width="11"
                    height="15"
                    viewBox="0 0 11 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.60496 14.6383C9.42024 14.6383 9.23359 14.5779 9.07773 14.457L0.923018 8.02084C0.724826 7.86414 0.609375 7.62814 0.609375 7.37704C0.609375 7.12782 0.724826 6.88993 0.923018 6.73512L9.0431 0.332906C9.40485 0.047818 9.934 0.104458 10.2246 0.459402C10.5151 0.814346 10.4574 1.33355 10.0956 1.61863L2.79141 7.37704L10.1322 13.1713C10.4939 13.4564 10.5517 13.9756 10.2611 14.3305C10.0937 14.5326 9.85126 14.6383 9.60496 14.6383Z"
                      fill="black"
                      fillOpacity="0.65"
                    />
                  </svg>
                </div>

                {this.state.pageArray &&
                  this.state.pageArray.map((item, key) => {
                    return (
                      <div
                        className={
                          this.state.current_page &&
                          this.state.current_page === item
                            ? "product-btn justify-center btn-search"
                            : "product-btn justify-center col-darkBlue"
                        }
                        key={key}
                        onClick={() => this.onPageClick(item)}
                      >
                        {item}
                      </div>
                    );
                  })}
                <div
                  className="product-btn justify-center"
                  onClick={() => this.onPageClick(this.state.total_page)}
                >
                  <svg
                    width="11"
                    height="15"
                    viewBox="0 0 11 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.39506 14.6383C1.57978 14.6383 1.76643 14.5779 1.92229 14.457L10.077 8.02084C10.2752 7.86414 10.3906 7.62814 10.3906 7.37704C10.3906 7.12782 10.2752 6.88993 10.077 6.73512L1.95692 0.332906C1.59518 0.047818 1.06603 0.104458 0.775474 0.459402C0.484922 0.814346 0.542647 1.33355 0.904394 1.61863L8.2086 7.37704L0.867834 13.1713C0.506087 13.4564 0.448362 13.9756 0.738914 14.3305C0.906319 14.5326 1.14877 14.6383 1.39506 14.6383Z"
                      fill="black"
                      fillOpacity="0.65"
                    />
                  </svg>
                </div>
              </div>
            </>
          ) : (
            <>
              {this.state.get_album && (
                <>
                  <div className="flex-grid2 album-grid">
                    <div className="album-grid-mt ">
                      {/* <img
                                                        className="album-img mouse-cursor"
                                                        src={this.state.get_album.thumbnail_images.full.url}
                                                        onClick={() => window.location = this.state.get_album.buttons}
                                                        alt="" />
                                                         */}
                    </div>

                    <div className="album-grid-mt justify-center col-darkBlue">
                      <div>
                        <div className="justify-center">
                          <div
                            className="mouse-cursor tooltip"
                            onClick={() => {
                              this.setState({ openImage: true });
                            }}
                            style={{ paddingBottom: "220px" }}
                          >
                            <img className="icon-size" src={maxIcon} alt="" />
                            <span className="tooltiptext">
                              {t("showImage")}
                            </span>
                          </div>
                          <img
                            className="album-thumbnail-img mouse-cursor"
                            src={this.state.get_album.images.small}
                            onClick={() =>
                              (window.location = this.state.get_album.buttons)
                            }
                            alt=""
                          />
                        </div>
                        <div className="pt-10 justify-center txt-bold txt-20">
                          {t("title")}:
                        </div>
                        <div className="justify-center">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.getLocalizedName(
                                this.state.get_album
                              ),
                            }}
                          />
                        </div>
                        <div className="pt-10 justify-center txt-bold txt-20">
                          {t("artists")}:
                        </div>
                        <table className="tList">
                          <thead>
                            <tr className="table-list album">
                              <th>{t("no")}</th>
                              <th>{t("id")}</th>
                              <th>{t("name")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.get_album &&
                              this.state.get_album.artists &&
                              this.state.get_album.artists.length > 0 &&
                              this.state.get_album.artists.map((item, key) => {
                                return (
                                  <tr key={key} className="table-list album">
                                    <td>{key + 1}</td>
                                    <td>{item.id}</td>
                                    <td>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: this.getLocalizedName(item),
                                        }}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                        {this.state.get_album.publisher?.length > 0 && (
                          <>
                            <div className="pt-10 justify-center txt-bold txt-20">
                              {t("publisher")}
                            </div>
                            <div className="justify-center">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: this.state.get_album.publisher,
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {this.allTracksRegistered() ? null : (
                    <div
                      style={{ width: "50px" }}
                      className="btn-common action mouse-cursor col-white mr-10 mt-10"
                      onClick={this.onAddAlbum}
                    >
                      {t("register")} {"tracks"}
                    </div>
                  )}
                  <div>
                    <div className="pt-30 pb-10 justify-center col-paragraphBg txt-20">
                      {t("tracks")}
                    </div>
                  </div>
                  <div className="table-p">
                    <table className="tList">
                      <thead>
                        <tr className="table-list album">
                          <th>{t("no")}</th>
                          <th>{t("title")}</th>
                          <th>{t("url")}</th>
                          <th>{t("played")}</th>
                          <th>{t("state")}</th>
                          <th>
                            {this.state.action_flag ? (
                              <div
                                className="justify-left"
                                style={{ paddingTop: 13 }}
                              >
                                <label className="container-event justify-left">
                                  <div
                                    className="col-paragraphBg"
                                    onClick={this.onActionClick}
                                  >
                                    {t("register")}
                                  </div>
                                  <input
                                    type="checkbox"
                                    checked={this.state.allChecked}
                                    onChange={this.onAllCheck}
                                  />
                                  <span className="checkMark" />
                                </label>
                              </div>
                            ) : (
                              <div
                                className="justify-left"
                                style={{ paddingTop: 13 }}
                              >
                                <label className="container-event justify-left">
                                  <div
                                    className="col-paragraphBg"
                                    onClick={this.onActionClick}
                                  >
                                    {t("unregister")}
                                  </div>
                                  <input
                                    type="checkbox"
                                    checked={this.state.allChecked}
                                    onChange={this.onAllCheck}
                                  />
                                  <span className="checkMark" />
                                </label>
                              </div>
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.get_album.tracks.map((item, key) => {
                          return (
                            <tr key={key} className="table-list album">
                              <td>{key + 1}</td>
                              <td>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: this.getLocalizedName(item),
                                  }}
                                />
                              </td>
                              <td>{item.url || item.file}</td>
                              <td>
                                {item.download === "" ? 0 : item.download}
                              </td>
                              <td>
                                {item.state === 1 ? (
                                  <div
                                    className="icon-flex tooltip"
                                    onClick={() => {
                                      this.setState(
                                        {
                                          new_checked: [item.id],
                                        },
                                        () => {
                                          this.onShowDeleteModal("tracks");
                                        }
                                      );
                                    }}
                                  >
                                    <img
                                      className="icon-size"
                                      src={bin28}
                                      alt="View"
                                    />
                                    <span className="tooltiptext">
                                      {t("registered_track")}
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    className="icon-flex tooltip"
                                    onClick={() => {
                                      this.setState(
                                        {
                                          new_checked: [item.id],
                                        },
                                        () => {
                                          this.onAddAlbum();
                                        }
                                      );
                                    }}
                                  >
                                    <img
                                      className="icon-size"
                                      src={addIcon}
                                      alt="Edit"
                                    />
                                    <span className="tooltiptext">
                                      {t("add_track")}
                                    </span>
                                  </div>
                                )}
                              </td>
                              <td>
                                {!this.state.action_flag &&
                                  item.state === 1 && (
                                    <label
                                      className="container-event"
                                      key={key}
                                    >
                                      <input
                                        id={item.id}
                                        type="checkbox"
                                        checked={this.state.new_checked.includes(
                                          item.id.toString()
                                        )}
                                        onChange={(e) => this.onCheckBox(e)}
                                      />
                                      <span className="checkMark" />
                                    </label>
                                  )}
                                {this.state.action_flag && item.state !== 1 && (
                                  <label className="container-event" key={key}>
                                    <input
                                      id={item.id}
                                      type="checkbox"
                                      checked={this.state.new_checked.includes(
                                        item.id.toString()
                                      )}
                                      onChange={(e) => this.onCheckBox(e)}
                                    />
                                    <span className="checkMark" />
                                  </label>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              {this.state.flag_range === true && (
                <>
                  <div className="table-p">
                    <table className="tList">
                      <thead>
                        <tr className="table-list album">
                          <th>{t("no")}</th>
                          <th>{t("id")}</th>
                          <th>{t("title")}</th>
                          <th>{t("image")}</th>
                          <th>{t("tracks")}</th>
                          <th>{t("state")}</th>
                          <th className="mouse-cursor">
                            {this.state.action_flag ? (
                              <div
                                className="justify-left"
                                style={{ paddingTop: 13 }}
                              >
                                <label className="container-event justify-left">
                                  <div
                                    className="col-paragraphBg"
                                    onClick={this.onActionClick}
                                  >
                                    {t("register")}
                                  </div>
                                  <input
                                    type="checkbox"
                                    checked={this.state.allChecked}
                                    onChange={this.onAllCheck}
                                  />
                                  <span className="checkMark" />
                                </label>
                              </div>
                            ) : (
                              <div
                                className="justify-left"
                                style={{ paddingTop: 13 }}
                              >
                                <label className="container-event justify-left">
                                  <div
                                    className="col-paragraphBg"
                                    onClick={this.onActionClick}
                                  >
                                    {t("unregister")}
                                  </div>
                                  <input
                                    type="checkbox"
                                    checked={this.state.allChecked}
                                    onChange={this.onAllCheck}
                                  />
                                  <span className="checkMark" />
                                </label>
                              </div>
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.get_albums &&
                          Object.keys(this.state.get_albums).map(
                            (item, key) => {
                              return (
                                <tr key={key}>
                                  <td style={{ paddingLeft: 20 }}>{key + 1}</td>
                                  <td>{this.state.get_albums[key].id}</td>
                                  <td>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: this.getLocalizedName(
                                          this.state.get_albums[key]
                                        ),
                                      }}
                                    />
                                  </td>
                                  <td>
                                    {this.state.get_albums[key].images &&
                                      this.state.get_albums[key].images
                                        .small && (
                                        <img
                                          className="thumbnail-size"
                                          src={
                                            this.state.get_albums[key].images
                                              .small
                                          }
                                          alt=""
                                        />
                                      )}
                                  </td>
                                  <td>
                                    {this.state.get_albums[key].tracks.length}
                                  </td>
                                  <td className="flex-common">
                                    {this.state.get_albums[key].state === 1 ? (
                                      <div
                                        className="icon-flex tooltip"
                                        onClick={() => {
                                          this.setState(
                                            {
                                              new_checked: [
                                                this.state.get_albums[key].id,
                                              ],
                                            },
                                            () => {
                                              this.onShowDeleteModal();
                                            }
                                          );
                                        }}
                                      >
                                        <img
                                          className="icon-size"
                                          src={bin28}
                                          alt="View"
                                        />
                                        <span className="tooltiptext">
                                          {t("registered_album")}
                                        </span>
                                      </div>
                                    ) : (
                                      <div
                                        className="icon-flex tooltip"
                                        onClick={() => {
                                          this.setState(
                                            {
                                              new_checked: [
                                                this.state.get_albums[key].id,
                                              ],
                                            },
                                            () => {
                                              this.onAddAlbum();
                                            }
                                          );
                                        }}
                                      >
                                        <img
                                          className="icon-size"
                                          src={addIcon}
                                          alt="Edit"
                                        />
                                        <span className="tooltiptext">
                                          {t("add_album")}
                                        </span>
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    {!this.state.action_flag &&
                                      this.state.get_albums[key].state ===
                                        1 && (
                                        <label
                                          className="container-event"
                                          key={key}
                                        >
                                          <input
                                            id={this.state.get_albums[key].id}
                                            type="checkbox"
                                            checked={this.state.new_checked.includes(
                                              this.state.get_albums[
                                                key
                                              ].id.toString()
                                            )}
                                            onChange={(e) => this.onCheckBox(e)}
                                          />
                                          <span className="checkMark" />
                                        </label>
                                      )}
                                    {this.state.action_flag &&
                                      this.state.get_albums[key].state !==
                                        1 && (
                                        <label
                                          className="container-event"
                                          key={key}
                                        >
                                          <input
                                            id={this.state.get_albums[key].id}
                                            type="checkbox"
                                            checked={this.state.new_checked.includes(
                                              this.state.get_albums[
                                                key
                                              ].id.toString()
                                            )}
                                            onChange={(e) => this.onCheckBox(e)}
                                          />
                                          <span className="checkMark" />
                                        </label>
                                      )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {/*  Modal  */}
        <DeleteAlbum
          show={this.state.show}
          handleClose={this.hideDeleteModal}
          albums={this.state.delete_album_id}
          flag={
            this.state.flag_range
              ? !this.state.flag_input // needs to be false because there is no input when using range so its in album deletion mode
              : this.state.flag_input && this.state.deletionMode != "album"
          }
          id={this.state.flag_range ? undefined : this.state.album_id}
          path={"/add-album"}
        />
        <Dialog
          open={this.state.openImage}
          onClose={() => {
            this.setState({ openImage: false });
          }}
          maxWidth="md"
          fullWidth
          style={{ marginTop: "40px" }}
        >
          <DialogTitle>
            <div
              className="mouse-cursor icon-flex tooltip"
              onClick={() => {
                this.setState({ openImage: false });
              }}
              style={{ position: "absolute", right: 8, top: 8, color: "grey" }}
            >
              <img className="icon-size" src={closeIcon} alt="" />
              <span className="tooltiptext">{t("close")}</span>
            </div>
          </DialogTitle>
          <DialogContent>
            <img
              className="mouse-cursor"
              src={this.state.get_album?.images?.small}
              onClick={() => (window.location = this.state.get_album.buttons)}
              alt="fullsize"
              style={{ width: "100%" }}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinning: state.users.spinning,
    get_album_list: state.users.get_album_list,
    msg_album_list_error: state.users.msg_album_list_error,
    msg_create_album: state.users.msg_create_album,
    get_album_byId: state.users.get_album_byId,
    msg_album_byId: state.users.msg_album_byId,
    get_album_byRangeId: state.users.get_album_byRangeId,
    msg_album_byRangeId: state.users.msg_album_byRangeId,
  };
};
export default connect(mapStateToProps, {
  reset,
  getAlbumListFromSite,
  addAlbumFromSite,
  getAlbumByIdFromSite,
  addTrackFromSite,
  getAlbumByRangeIdFromSite,
})(withTranslation("translations")(AddAlbum));
