import {
  SHOW_SPINNING,
  SHOW_TOTALS_SPINNING,
  USER_ERROR,
  USER_REGISTRATION,
  CREATE_PUBLISHER,
  ERROR_CREATE_PUBLISHER,
  GET_USER_ERROR,
  GET_USERS,
  DELETE_USER,
  GET_USER,
  ERROR_USER,
  GET_ALL_USERS,
  GET_EXISTING_ALBUM,
  ERROR_ALBUM_LIST,
  ADD_ALBUM,
  DELETE_ALBUM,
  UPDATE_ALBUM,
  ALL_ARTISTS,
  GET_REGISTERED_ALBUMS,
  ERROR_REGISTERED_ALBUM_LIST,
  GET_ALL_REGISTERED_ALBUMS,
  ERROR_ALL_REGISTERED_ALBUM_LIST,
  ASSIGN_ALBUMS,
  ERROR_ASSIGN_ALBUMS,
  GET_ALBUM_BYID,
  ERROR_ALBUM_BYID,
  GET_ALBUM_BYRANGEID,
  ERROR_ALBUM_BYRANGEID,
  GET_TRACK_LIST,
  ERROR_TRACK_LIST,
  TRACK_UPDATE_PAYMENT,
  UPDATE_PAID,
  GET_SEARCH_USERS,
  USER_LOGOUT,
  DELETE_HISTORY_ERROR,
  DELETE_HISTORY,
  GET_SEARCH_ALBUMS,
  MSG_SEARCH_ALBUMS,
  GET_TOTAL_OWED,
  MSG_TOTAL_OWED,
  GET_PLAY_LIST,
  GET_MOST_PLAYED,
  GET_REVENUECAT,
  SEND_EMAIL
} from "../actions/types/types";

const initialState = {
  spinning: "",
  totals_spinning: "",
  msg_user_error: "",
  msg_registration: {},
  msg_create_publisher: "",
  msg_publisher_error: "",
  getUser_list: "",
  msg_get_users: "",
  msg_user_delete: "",
  msg_send_email:"",
  get_user: "",
  msg_user: "",
  getAll_users: "",
  allArtists: [],

  get_album_list: "",
  msg_album_list_error: "",
  msg_create_album: "",

  get_registered_albums: "",
  msg_registered_albums: "",

  get_all_registered_albums: {},
  msg_all_registered_albums: {},

  msg_assign_albums: "",
  msg_error_assign_albums: "",

  get_album_byId: "",
  msg_album_byId: "",
  get_album_byrangeId: "",
  msg_album_byrangeId: "",
  get_track_list: "",
  msg_error_tracks: "",

  msg_track_update: "",
  msg_paid: "",
  msg_err_delete_history: "",
  msg_delete_history: "",
  get_search_albums: "",
  msg_search_albums: "",

  get_total_owed: "",
  msg_total_owed: "",
  get_play_list:"",
  get_most_played:"",
  get_revenuecat:""
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SEARCH_ALBUMS:
      return {
        ...state,
        get_search_albums: action.payload,
      };
    case MSG_SEARCH_ALBUMS:
      return {
        ...state,
        msg_search_albums: action.payload,
      };
    case GET_TOTAL_OWED:
      return {
        ...state,
        get_total_owed: action.payload,
      };
    case MSG_TOTAL_OWED:
      return {
        ...state,
        msg_total_owed: action.payload,
      };
    case DELETE_HISTORY_ERROR:
      return {
        ...state,
        msg_err_delete_history: action.payload,
      };
    case DELETE_HISTORY:
      return {
        ...state,
        msg_delete_history: action.payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        msg_logout: action.payload,
      };
    case GET_SEARCH_USERS:
      return {
        ...state,
        get_search_users: action.payload,
      };
    case UPDATE_PAID:
      return {
        ...state,
        msg_paid: action.payload,
      };
    case TRACK_UPDATE_PAYMENT:
      return {
        ...state,
        msg_track_update: action.payload,
      };
    case GET_TRACK_LIST:
      return {
        ...state,
        get_track_list: action.payload,
      };
    case ERROR_TRACK_LIST:
      return {
        ...state,
        msg_error_tracks: action.payload,
      };
    case GET_ALBUM_BYID:
      return {
        ...state,
        get_album_byId: action.payload,
      };
    case ERROR_ALBUM_BYID:
      return {
        ...state,
        msg_album_byId: action.payload,
      };
      case GET_ALBUM_BYRANGEID:
      return {
        ...state,
        get_album_byRangeId: action.payload,
      };
    case ERROR_ALBUM_BYRANGEID:
      return {
        ...state,
        msg_album_byRangeId: action.payload,
      };
    case ASSIGN_ALBUMS:
      return {
        ...state,
        msg_assign_albums: action.payload,
      };
    case ERROR_ASSIGN_ALBUMS:
      return {
        ...state,
        msg_error_assign_albums: action.payload,
      };
    case GET_ALL_USERS:
      return {
        ...state,
        getAll_users: action.payload,
      };
    case GET_REGISTERED_ALBUMS:
      return {
        ...state,
        get_registered_albums: action.payload,
      };
    case ERROR_REGISTERED_ALBUM_LIST:
      return {
        ...state,
        msg_registered_albums: action.payload,
      };
    case GET_ALL_REGISTERED_ALBUMS:
      return {
        ...state,
        get_all_registered_albums: {
          ...state.msg_all_registered_albums,
          [action.payload.id]: action.payload.data,
        },
      };
    case ERROR_ALL_REGISTERED_ALBUM_LIST:
      return {
        ...state,
        msg_all_registered_albums: {
          ...state.msg_all_registered_albums,
          [action.payload.id]: action.payload.error,
        },
      };
    case DELETE_ALBUM:
      return {
        ...state,
        msg_album_delete: action.payload,
      };
    case UPDATE_ALBUM:
      return {
        ...state,
        msg_album_update: action.payload,
      };
    case ALL_ARTISTS:
      return {
        ...state,
        allArtists: action.payload,
      };
    case ADD_ALBUM:
      return {
        ...state,
        msg_create_album: action.payload,
      };
    case GET_EXISTING_ALBUM:
      return {
        ...state,
        get_album_list: action.payload,
      };
    case ERROR_ALBUM_LIST:
      return {
        ...state,
        msg_album_list_error: action.payload,
      };
    case GET_USER:
      return {
        ...state,
        get_user: action.payload,
      };
    case ERROR_USER:
      return {
        ...state,
        msg_user: action.payload,
      };
    case DELETE_USER:
      return {
        ...state,
        msg_user_delete: action.payload,
      };
    case SEND_EMAIL:
      return {
        ...state,
        msg_send_email:action.payload,
      }
    case GET_USERS:
      return {
        ...state,
        getUser_list: action.payload,
      };
    case GET_USER_ERROR:
      return {
        ...state,
        msg_get_users: action.payload,
      };
    case ERROR_CREATE_PUBLISHER:
      return {
        ...state,
        msg_publisher_error: action.payload,
      };
    case CREATE_PUBLISHER:
      return {
        ...state,
        msg_create_publisher: action.payload,
      };
    case SHOW_SPINNING:
      return {
        ...state,
        spinning: action.payload,
      };
    case SHOW_TOTALS_SPINNING:
      return {
        ...state,
        totals_spinning: action.payload,
      };
    case USER_REGISTRATION:
      return {
        ...state,
        msg_registration: action.payload,
      };

    case USER_ERROR:
      return {
        ...state,
        msg_user_error: action.payload,
      };
    case GET_PLAY_LIST:
      return {
        ...state,
        get_play_list: action.payload,
      }
    case GET_MOST_PLAYED:
      return {
       ...state,
        get_most_played: action.payload,
      }
    case GET_REVENUECAT:
      return{
        ...state,
        get_revenuecat:action.payload
      }
    default:
      return state;
  }
}
