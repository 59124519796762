import React, { Component, useEffect } from "react";
import {
  getPlaysList,
  getRevenueCatData,
  getMostPlayedTrack,
  exportCSVPlayed,
  exportPublishersOwed,
} from "../redux/actions/users/management";
import { connect } from "react-redux";
import { startOfMonth, endOfMonth, format } from "date-fns";

import axios from "axios";

import { withTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  Text,
  ResponsiveContainer,
  RadialBarChart,
  RadialBar,
} from "recharts";
import { valHooks } from "jquery";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Autocomplete,
  TextField,
  Grid,
  getMobileStepperUtilityClass,
} from "@mui/material";
import DateRangePicker from "./DateRangePicker";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import countries from "./countries.json";
import Papa from "papaparse";

import dropDownBlackIcon from "../assets/images/drop-down-black.svg";
import dropdownIcon from "../assets/images/drop-down.svg";
import spreadsheetIcon from "../assets/images/spreadsheet.svg";
import spreadsheetIcon1 from "../assets/images/icons8-export-excel-50 (2).png";

import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import iconUrl from "leaflet/dist/images/marker-icon.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";
import "../assets/css/dashboard.css";
import exportExcel from "../assets/images/icons8-export-excel-50 (2).png";

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listPlays: "",
      listCountryPlays: "",
      finish: 0,
      allPlays: "",
      totalD: 0,
      totalS: 0,
      totalPlaysD: 0,
      totalPlaysS: 0,
      dataDular: [],
      dataShekel: [],
      numPlaysDular: [],
      numPlayShekel: [],
      dataCountry: [],
      month: "",
      selectedCountry: null,
      filterValue: "",
      filteredData: [],
      start: startOfMonth(new Date()),
      end: endOfMonth(new Date()),
      revenueCatData: "",
      showRevenue: false,
      limit: 100,
      listMostPlayed: "",
      csvLoading: false,
    };
  }
  componentDidMount() {
    this.getInitialPlaysList();
    this.fetchAnalyticsData();
    this.getMostPlayed();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.get_most_played &&
      this.props.get_most_played !== prevProps.get_most_played
    ) {
      this.setState({
        listMostPlayed: this.props.get_most_played.mostPlayed,
      });
    }

    if (
      this.props.get_play_list &&
      this.props.get_play_list !== prevProps.get_play_list
    ) {
      if (this.props.get_play_list.plays) {
        this.setState({
          listPlays: this.props.get_play_list.plays.plays,
          listCountryPlays: this.props.get_play_list.plays.countryPlays,
        });
      } else {
        this.setState({ listPlays: "", listCountryPlays: "" });
      }
    }

    if (prevState.listPlays !== this.state.listPlays) {
      const listPlays = this.state.listPlays;
      if (listPlays) {
        let subD = 0;
        let playD = 0;
        let noSubD = 0;
        let noPlayD = 0;
        let subS = 0;
        let playS = 0;
        let noSubS = 0;
        let noPlayS = 0;

        listPlays.map((item) => {
          if (item._id.currency == "$") {
            if (item._id.subscribed) {
              subD += item.price;
              playD += item.plays;
            } else {
              noSubD += item.price;
              noPlayD += item.plays;
            }
          } else {
            if (item._id.subscribed) {
              subS += item.price;
              playS += item.plays;
            } else {
              noSubS += item.price;
              noPlayS += item.plays;
            }
          }
        });
        const dataD = [
          { name: "subscribed", value: subD },
          { name: "no_subscribed", value: noSubD },
        ];
        const dataS = [
          { name: "subscribed", value: subS },
          { name: "no_subscribed", value: noSubS },
        ];
        const dataPlayD = [
          { name: "subscribed", value: playD },
          { name: "no_subscribed", value: noPlayD },
        ];
        const dataPlayS = [
          { name: "subscribed", value: playS },
          { name: "no_subscribed", value: noPlayS },
        ];
        this.setState({
          dataDular: dataD,
          dataShekel: dataS,
          numPlaysDular: dataPlayD,
          numPlayShekel: dataPlayS,
          totalD: noSubD + subD,
          totalS: noSubS + subS,
          totalPlaysD: playD + noPlayD,
          totalPlaysS: playS + noPlayS,
          allPlays: playD + noPlayD + playS + noPlayS,
        });
      }
    }

    if (
      prevState.listCountryPlays !== this.state.listCountryPlays &&
      this.state.listCountryPlays
    ) {
      const mappedCoordinates = this.state.listCountryPlays
        .map((item) => {
          const countryData = countries.find(
            (country) => country.key === item._id
          );
          if (countryData) {
            return {
              ...item,
              lat: countryData.latitude,
              lng: countryData.longitude,
              label: countryData.name,
            };
          }
          return null;
        })
        .filter((coord) => coord !== null);
      this.setState({
        dataCountry: mappedCoordinates,
        filteredData: mappedCoordinates,
      });
    }
    if (
      this.props.get_revenuecat &&
      this.props.get_revenuecat !== prevProps.get_revenuecat
    ) {
      this.setState({ revenueCatData: this.props.get_revenuecat.metrics });
    }
    if (prevState.revenueCatData !== this.state.revenueCatData) {
      console.log(this.state.revenueCatData);
    }
  }

  getInitialPlaysList = () => {
    const { getPlaysList } = this.props;
    if (getPlaysList) {
      const data = { role_id: localStorage.id };
      data.range = {
        start: startOfMonth(new Date()),
        end: endOfMonth(new Date()),
      };
      getPlaysList(data);
    }
    this.setState({ finish: 9 });
    // const {getMostPlaysTrack} = this.props;
    // if(getMostPlaysTrack)
    //     {getMostPlaysTrack();}
  };

  getMostPlayed = () => {
    const { getMostPlayedTrack } = this.props;
    console.log(this.state.limit);
    if (getMostPlayedTrack) {
      const data = {
        range: {
          start: this.state.start,
          end: this.state.end,
        },
        limit: this.state.limit,
      };
      getMostPlayedTrack(data);
    }
  };

  onSearchKeyUp = (code) => {
    if (code === 13) {
      this.getMostPlayed();
    }
  };
  onSearch = (limit) => {
    // this.getInitialPlaysList();

    const { getPlaysList } = this.props;
    if (getPlaysList) {
      const data = { role_id: localStorage.id };
      data.range = {
        start: this.state.start,
        end: this.state.end,
      };
      getPlaysList(data);
    }
    this.setState({ finish: 9 });

    this.getMostPlayed();
  };

  handleFilterChange = (event, value) => {
    this.setState({ filterValue: value ? value.label : "" });
    if (value) {
      const newfilteredData = this.state.filteredData.filter((item) =>
        item.label.toLowerCase().includes(value.label.toLowerCase())
      );
      this.setState({ filteredData: newfilteredData });
    } else this.setState({ filteredData: this.state.dataCountry });
  };

  fetchAnalyticsData = () => {
    const { getRevenueCatData } = this.props;
    if (getRevenueCatData) {
      getRevenueCatData();
    }
    this.setState({ finish: 9 });
  };

  exportQuarterlyCSV = async () => {
    this.setState({ csvLoading: true });

    const data = {
      role_id: localStorage.id,
    };

    exportPublishersOwed(data)
      .then((res) => {
        if (!res?.result?.length) {
          throw new Error("Invalid response structure");
        }

        let parser = new DOMParser();

        // Format results with owed, paid, and total
        let formattedResults = res.result.map((item) => ({
          publisher_id: item.publisher_id,
          publisher_name: item.publisher_name,
          total_owed: item.total_owed.toFixed(2) + " " + item.currency, // Owed amount
          total_paid: item.total_paid.toFixed(2) + " " + item.currency, // Paid amount
          total_after_payments:
            (item.total_owed - item.total_paid).toFixed(2) +
            " " +
            item.currency, // Owed - Paid
        }));

        // Parse HTML in strings (if necessary)
        formattedResults.forEach((item) => {
          for (let key in item) {
            if (typeof item[key] === "string") {
              item[key] = parser.parseFromString(
                item[key],
                "text/html"
              ).body.textContent;
            }
          }
        });

        let csv = Papa.unparse(formattedResults);
        let name = "Total_Owed_Report.csv";
        this.downloadCSV(csv, name);
      })
      .catch((err) => {
        console.log("Error fetching report:", err);
      })
      .finally(() => {
        this.setState({ csvLoading: false });
      });
  };

  exportDetailedCSV = async () => {
    const data = {
      role_id: localStorage.id,
      range: {
        start: this.state.start,
        end: this.state.end,
      },
    };

    this.setState({ csvLoading: true });
    exportCSVPlayed(data)
      .then((res) => {
        let parser = new DOMParser();
        res.forEach((item) => {
          for (let key in item) {
            if (typeof item[key] === "string") {
              item[key] = parser.parseFromString(
                item[key],
                "text/html"
              ).body.textContent;
            }
          }
        });
        let csv = Papa.unparse(res);
        let name = this.getDateRange() + "report.csv";
        this.downloadCSV(csv, name);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ csvLoading: false });
      });
  };

  getDateRange() {
    const { start, end } = this.state;

    const startDate = format(start, "yyyy-MM-dd");
    const endDate = format(end, "yyyy-MM-dd");

    return `${startDate}-${endDate}-`;
  }

  downloadCSV = (csv, filename) => {
    let csvFile;
    let downloadLink;
    csvFile = new Blob(["\ufeff" + csv], {
      type: "text/csv;charset=charset=utf-8",
    });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  render() {
    const { t } = this.props;
    const COLORS = ["#A528D8", "#c155ec87"];
    const COLORS2 = ["#A528D8", "#c155ec87"];

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const currentStartYear = this.state.start.getFullYear();
    const currentStartMonthIndex = this.state.start.getMonth();
    const currentEndYear = this.state.end.getFullYear();
    const currentEndMonthIndex = this.state.end.getMonth();

    return (
      <>
        <div
          className="spinning-curtain"
          style={{ display: this.props.spinning ? "flex" : "none" }}
        >
          <div className="lds-dual-ring" />
        </div>
        <ToastContainer />
        <div className="col-darkBlue txt-30 txt-bold pb-20 pl-40">
          {t("dashboard")}
          {": "}
          {currentStartMonthIndex === currentEndMonthIndex &&
          currentStartYear === currentEndYear
            ? t(months[currentStartMonthIndex]) + " " + currentStartYear
            : currentStartYear === currentEndYear
            ? t(months[currentStartMonthIndex]) +
              "-" +
              t(months[currentEndMonthIndex]) +
              " " +
              currentStartYear
            : t(months[currentStartMonthIndex]) +
              " " +
              currentStartYear +
              "-" +
              t(months[currentEndMonthIndex]) +
              " " +
              currentEndYear}
          {/* {currentStartMonthIndex===currentEndMonthIndex && currentStartYear!==currentEndYear? 
                    t(months[currentStartMonthIndex])+" "+currentStartYear+"-"+t(months[currentEndMonthIndex])+" "+currentEndYear:""} */}
        </div>
        <div className="col-darkBlue txt-30 txt-bold pb-20 pl-40">
          {t("plays") + ": " + this.state.allPlays}
        </div>
        {this.state.csvLoading ? (
          <div
            className="spinning-curtain-small"
            style={{
              display: this.state.csvLoading ? "inline-block" : "none",
              position: "relative",
              width: "unset",
              marginLeft: "10px",
              borderRadius: "50%",
            }}
          >
            <div className="lds-dual-ring-small" />
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                className="ml-10 mouse-cursor tooltip pl-40"
                onClick={(e) => {
                  this.exportDetailedCSV();
                }}
                style={{ display: "inline-block" }}
              >
                <img
                  className="icon-size mb-id-10 mt-id-10"
                  src={spreadsheetIcon1}
                  alt=""
                  style={{
                    borderRadius: "30%",
                    width: "50px",
                    height: "50px",
                    border: "1px solid #c155ec87",
                    padding: "5px",
                  }}
                />
                <span className="tooltiptext">{t("export_detailed_csv")}</span>
              </div>
              <div
                onClick={(e) => {
                  this.exportQuarterlyCSV();
                }}
                className="mt-20 mb-30 cursor-pointer "
                style={{ padding: "5px" }}
              >
                <div
                  className="btn-common cursor-pointer inline-block text-center"
                  style={{ padding: "15px" }}
                >
                  <img
                    src={spreadsheetIcon1}
                    className="cursor-pointer"
                    style={{
                      width: 25,
                      backgroundColor: "white",
                      borderRadius: 5,
                      marginRight: 15,
                    }}
                    alt=""
                  />
                  הורד קובץ רבעוני
                </div>
              </div>
            </div>
          </>
        )}
        <DateRangePicker
          start={this.state.start}
          end={this.state.end}
          onChangeStart={(start) => this.setState({ start })}
          onChangeEnd={(end) => this.setState({ end })}
          dash={true}
          onSearch={() => this.onSearch(false)}
          // checked={this.state.allTotals}
          // onCheckChange={() => {
          //   this.setState({ allTotals: !this.state.allTotals }, () => {
          //     this.getTotals();
          //   });
          // }}
        />

        <div className="card-container">
          {/* <Stack direction="row" spacing={2} > */}
          {/* total amount dular */}
          <Paper elevation={3} sx={{ width: 300, height: 300 }}>
            <div className="col-darkBlue txt-bold pb-20 pl-40 pt-20">
              {t("total_amount") + ": " + this.state.totalD.toFixed(2) + "$"}
            </div>
            <PieChart width={300} height={200}>
              <Pie
                data={this.state.dataDular}
                // cx="50%" cy="50%"
                // cx={200}
                // cy={200}
                // labelLine={false}
                // label={({ name, percent,value }) => `${name} ${(percent * 100).toFixed(0)}%\n${value.toFixed(2)}`}
                outerRadius={50}
                innerRadius={40}
                fill="#8884d8"
                dataKey="value"
              >
                {this.state.dataDular &&
                  this.state.dataDular.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
              </Pie>
              <text
                className="col-darkBlue txt-bold"
                x="50%"
                y="38%"
                textAnchor="middle"
                dominantBaseline="central"
                fontSize="40px"
              >
                $
              </text>
              <Tooltip />
              <Legend
                formatter={(value, entry) => {
                  const { name, percent, value: dataValue } = entry.payload;
                  return `${t(name)} | ${(percent * 100).toFixed(
                    0
                  )}% | ${dataValue.toFixed(2)}$`;
                }}
              />
            </PieChart>
          </Paper>
          {/* plays dular */}
          <Paper elevation={3} sx={{ width: 300, height: 300 }}>
            <div className="col-darkBlue txt-bold pb-20 pl-40 pt-20">
              {t("plays") + ": " + this.state.totalPlaysD}
            </div>
            <PieChart width={300} height={200}>
              <Pie
                data={this.state.numPlaysDular}
                // cx={200}
                // cy={200}
                // labelLine={false}
                // label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                outerRadius={50}
                innerRadius={40}
                fill="#8884d8"
                dataKey="value"
              >
                {this.state.numPlaysDular &&
                  this.state.numPlaysDular.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS2[index % COLORS2.length]}
                    />
                  ))}
              </Pie>
              <text
                className="col-darkBlue txt-bold"
                x="50%"
                y="38%"
                textAnchor="middle"
                dominantBaseline="central"
                fontSize="40px"
              >
                $
              </text>
              <Tooltip />
              {/* <Legend /> */}
              <Legend
                formatter={(value, entry) => {
                  const { name, percent, value: dataValue } = entry.payload;
                  return `${t(name)} | ${(percent * 100).toFixed(
                    0
                  )}% | ${dataValue}`;
                }}
              />
            </PieChart>
          </Paper>
          {/* total amunt shekel */}
          <Paper elevation={3} sx={{ width: 300, height: 300 }}>
            <div className="col-darkBlue txt-bold pb-20 pl-40 pt-20">
              {t("total_amount") + ": " + this.state.totalS.toFixed(2) + "₪"}
            </div>
            <PieChart width={300} height={200}>
              <Pie
                data={this.state.dataShekel}
                // cx={200}
                // cy={200}
                // labelLine={false}
                // label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                outerRadius={50}
                innerRadius={40}
                fill="#8884d8"
                dataKey="value"
              >
                {this.state.dataShekel &&
                  this.state.dataShekel.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
              </Pie>

              <text
                className="col-darkBlue txt-30 txt-bold"
                x="50%"
                y="38%"
                textAnchor="middle"
                dominantBaseline="central"
                fontSize="40px"
              >
                ₪
              </text>
              <Tooltip />
              {/* <Legend /> */}
              <Legend
                formatter={(value, entry) => {
                  const { name, percent, value: dataValue } = entry.payload;
                  return `${t(name)} | ${(percent * 100).toFixed(
                    0
                  )}% | ${dataValue.toFixed(2)}₪`;
                }}
              />
            </PieChart>
          </Paper>
          {/* plays shekel */}
          <Paper elevation={3} sx={{ width: 300, height: 300 }}>
            <div className="col-darkBlue txt-bold pb-20 pl-40 pt-20">
              {t("plays") + ": " + this.state.totalPlaysS}
            </div>
            <PieChart width={300} height={200}>
              <Pie
                data={this.state.numPlayShekel}
                // cx={200}
                // cy={200}
                // labelLine={false}
                // label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                outerRadius={50}
                innerRadius={40}
                fill="#8884d8"
                dataKey="value"
              >
                {this.state.numPlayShekel &&
                  this.state.numPlayShekel.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS2[index % COLORS2.length]}
                    />
                  ))}
              </Pie>
              <text
                className="col-darkBlue txt-30 txt-bold"
                x="50%"
                y="38%"
                textAnchor="middle"
                dominantBaseline="central"
                fontSize="40px"
              >
                ₪
              </text>
              <Tooltip />
              {/* <Legend /> */}
              <Legend
                formatter={(value, entry) => {
                  const { name, percent, value: dataValue } = entry.payload;
                  return `${t(name)} | ${(percent * 100).toFixed(
                    0
                  )}% | ${dataValue}`;
                }}
              />
            </PieChart>
          </Paper>
          {/* </Stack> */}
        </div>
        <div className="card-container">
          {/* <Stack direction="row" spacing={2} sx={{marginTop:2}}> */}
          <Paper
            elevation={3}
            sx={{ width: 616, height: 400, padding: 2, marginTop: 2 }}
          >
            <MapContainer
              center={[20, 0]}
              zoom={1}
              style={{ height: "100%", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {this.state.dataCountry.map((item, index) => (
                <Marker key={index} position={[item.lat, item.lng]}>
                  <Popup
                  // mouseOverDelay={200}
                  // mouseOutDelay={100}
                  >
                    <div>
                      <h4 className="col-darkBlue txt-f txt-bold">
                        {item.label}
                      </h4>
                      <h6 className="col-darkBlue txt-f txt-bold ">
                        {t("plays")} : {item.playsNoSub + item.playsSub}
                      </h6>
                    </div>
                  </Popup>
                </Marker>
              ))}
            </MapContainer>
          </Paper>

          {this.state.filteredData.length > 0 && (
            <Paper
              elevation={3}
              sx={{ width: 400, height: 400, padding: 2, marginTop: 2 }}
            >
              <div>
                <div className="table-d">
                  <table className="tList">
                    <thead>
                      <tr
                        className="table-list"
                        style={{ position: "sticky", top: 2, zIndex: 1 }}
                      >
                        <th>{t("country")}</th>
                        <th>{t("subscribed")}</th>
                        <th>{t("no_subscribed")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.filteredData.map((item, key) => (
                        <tr key={key} className="table-list">
                          <td>{item.label}</td>
                          <td>{item.playsSub}</td>
                          <td>{item.playsNoSub}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Paper>
          )}

          {/* </Stack> */}
        </div>
        <div className="card-container">
          <div className="justify-left user-search">
            <div className="mr-id-5" style={{ width: "100px" }}>
              <input
                id="limit"
                className="count mouse-cursor"
                placeholder={"number"}
                value={this.state.limit}
                onChange={(e) => this.setState({ limit: e.target.value })}
                onKeyUp={(e) => this.onSearchKeyUp(e.keyCode)}
              />
            </div>
            <div className="col-darkBlue txt-30 txt-bold pl-40">
              {" "}
              {t("most_played_tracks")}
            </div>

            {/* <div
                  className="btn-common user-search mouse-cursor col-white"
                  onClick={this.onSearch(true)}
                >
                  {t("search")}
                </div> */}
          </div>
          <div className="table-p">
            <table className="tList">
              <thead className="track-list">
                <tr className="table-list">
                  <th>{t("Num")}</th>
                  <th>{t("title")}</th>
                  <th>{t("album")}</th>
                  <th>{t("artist")}</th>
                  <th>{t("price_per_track")}</th>
                  <th>{t("played")}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.listMostPlayed &&
                  this.state.listMostPlayed.length > 0 &&
                  this.state.listMostPlayed.map((item, key) => {
                    return (
                      <tr key={key} className="table-list">
                        <td>{key + 1}</td>
                        <td>{item.track_name}</td>
                        <td>{item.album_name}</td>
                        <td>{item.publisher_name}</td>
                        <td>{item.price_per_track}</td>
                        <td>{item.count}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>

        <div
          onClick={() => {
            this.setState({ showRevenue: !this.state.showRevenue });
          }}
          style={this.state.showRevenue ? { transform: "rotate(-180deg)" } : {}}
          className="mouse-cursor"
        >
          <img
            className="drop-icon"
            src={dropDownBlackIcon}
            style={
              this.state.showRevenue ? { paddingRight: 10, paddingLeft: 0 } : {}
            }
            // alt="drop down"
          />
        </div>
        {this.state.showRevenue && (
          <div className="card-container">
            {this.state.revenueCatData &&
              this.state.revenueCatData.map((item) => {
                return (
                  <div className="card">
                    <div className="card-content">
                      <h2 className="card-name">{item.name}</h2>
                      <p className="card-value">{item.value}</p>
                      <p className="card-description">{item.description}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinning: state.users.spinning,
    get_play_list: state.users.get_play_list,
    get_revenuecat: state.users.get_revenuecat,
    get_most_played: state.users.get_most_played,
  };
};

export default connect(mapStateToProps, {
  getPlaysList,
  getRevenueCatData,
  getMostPlayedTrack,
})(withTranslation("translations")(Dashboard));
