import React from "react";
import { connect } from "react-redux";
import "../../assets/css/dashboard.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { reset, getTrackListById } from "../../redux/actions/users/management";
import { withTranslation } from "react-i18next";
import { decodeHtml } from "../../utils";
import viewIcon from "../../assets/images/viewFile.svg"
const config = require("../../config/config");

class UserTrackList extends React.Component {
  constructor(props) {
    super(props);
    this.tmr = null;
    this.state = {
      role: localStorage.role,
      user_id: localStorage.id,
      listById: "",
      currency: "",

      table_show: true,
      detail_show: false,
      detail_item: "",
    };
  }
  componentDidMount() {
    this.getInitialTrackList();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.get_track_list &&
      this.props.get_track_list !== prevProps.get_track_list
    ) {
      this.setState({
        listById: this.props.get_track_list,
        currency: this.props.get_track_list.currency,
      });
    }
    if (
      this.props.msg_error_tracks &&
      this.props.msg_error_tracks !== prevProps.msg_error_tracks
    ) {
      toast(this.props.msg_error_tracks);
      const { reset } = this.props;
      clearTimeout(this.tmr);
      this.tmr = setTimeout(function () {
        reset();
        this.tmr = null;
      }, 3000);
    }
  }
  getInitialTrackList = () => {
    const { getTrackListById } = this.props;
    if (getTrackListById) {
      let path = this.props.match.params && this.props.match.params.id;
      const data = {
        user_id: this.state.user_id,
        album_id: path,
        role: localStorage.getItem("role"),
      };
      getTrackListById(data);
    }
  };
  onShowTableContents = () => {
    this.setState({
      table_show: !this.state.table_show,
    });
  };
  onShowDetail = (item) => {
    this.setState({
      table_show: false,
      detail_show: true,
      detail_item: item,
    });
  };
  exportTableToCSV = (filename) => {
    let csv = [];
    let rows = document.querySelectorAll("table tr");
    for (let i = 0; i < rows.length; i++) {
      let row = [],
        cols = rows[i].querySelectorAll("td, th");
      for (let j = 0; j < cols.length; j++) row.push(decodeHtml(cols[j].innerText));

      csv.push(row.join(","));
    }
    this.downloadCSV(csv.join("\n"), filename);
  };
  downloadCSV = (csv, filename) => {
    let csvFile;
    let downloadLink;
    csvFile = new Blob(["\ufeff" + csv], {
      type: "text/csv;charset=charset=utf-8",
    });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <div
          className="spinning-curtain"
          style={{ display: this.props.spinning ? "flex" : "none" }}
        >
          <div className="lds-dual-ring" />
        </div>
        <ToastContainer />
        <div className="col-darkBlue txt-30 txt-bold pb-20 pl-40">
          {t("track_list")}
        </div>
        <div className="form-bg">
          <div className="flex-space album-list">
            <div
              className="btn-common action mouse-cursor col-white mr-id-10"
              onClick={() => this.props.history.goBack()}
            >
              {t("back")}
            </div>
          </div>
          {this.state.listById && (
            <div className="flex-grid2 album-grid">
              <div className="album-grid-mt">
                <img
                  className="album-img mouse-cursor"
                  src={this.state.listById.album.full_thumbnail}
                  onClick={() =>
                    (window.location = this.state.listById.album.path)
                  }
                  alt=""
                />
              </div>
              <div className="album-grid-mt justify-center col-darkBlue">
                <div>
                  <div className="justify-center">
                    <img
                      className="album-thumbnail-img mouse-cursor"
                      src={this.state.listById.album.thumbnail}
                      onClick={() =>
                        (window.location = this.state.listById.album.path)
                      }
                      alt=""
                    />
                  </div>
                  <div className="pt-10 justify-center txt-bold txt-20">
                    {t("title")}:
                  </div>
                  <div className="justify-center">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.listById.album.name,
                      }}
                    />
                  </div>
                  <div className="pt-10 justify-center txt-bold txt-20">
                    {t("artists")}:
                  </div>
                  <table className="tList">
                    <thead>
                      <tr className="table-list album">
                        <th>{t("Num")}</th>
                        <th>{t("id")}</th>
                        <th>{t("name")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.listById &&
                        this.state.listById.album.artists.map((item, key) => {
                          return (
                            <tr key={key} className="align-left">
                              <td style={{ paddingLeft: 20 }}>{key + 1}</td>
                              <td style={{ paddingLeft: 20 }}>{item.id}</td>
                              <td style={{ paddingLeft: 20 }}>{item.title}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          <div>
            <div className="pt-30 pb-10 justify-center col-paragraphBg txt-20">
              {t("tracks")}
            </div>
          </div>
          <div className="table-p">
            <table className="tList">
              <thead>
                <tr
                  className="table-list album mouse-cursor"
                  onClick={this.onShowTableContents}
                >
                  <th>{t("Num")}</th>
                  <th>{t("title")}</th>
                  <th>{t("played")}</th>
                  <th>{t("current_price")}</th>
                  <th>{t("total")}</th>
                  <th>{t("paid")}</th>
                  <th>{t("owed")}</th>
                  <th>{t("detail_view")}</th>
                </tr>
              </thead>
              {this.state.table_show && (
                <tbody>
                  {this.state.listById &&
                    this.state.listById.tracks.map((item, key) => {
                      return (
                        <tr key={key} className="table-list album">
                          <td>{key + 1}</td>
                          <td>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.name }}
                            />
                          </td>
                          <td>
                            {item.download === "" || item.download === null
                              ? 0
                              : item.download}
                          </td>
                          <td>
                            {(item.amount_per_one ? item.amount_per_one : 0) +
                              (this.state.currency
                                ? this.state.currency
                                : config.SYMBOL[0])}
                          </td>
                          <td>
                            {(item.total_amount
                              ? item.total_amount.toFixed(2)
                              : 0) +
                              (this.state.currency
                                ? this.state.currency
                                : config.SYMBOL[0])}
                          </td>
                          <td>
                            <div>
                              {(item.paid_amount
                                ?item.paid_amount.toFixed(
                                    2
                                  )
                                : 0) +
                                (this.state.currency
                                  ? this.state.currency
                                  : config.SYMBOL[0])}
                            </div>
                          </td>
                          <td className="col-paragraphBg txt-bold">
                            {(item.owed_amount
                              ? item.owed_amount.toFixed(
                                  2
                                )
                              : 0) +
                              (this.state.currency
                                ? this.state.currency
                                : config.SYMBOL[0])}
                          </td>
                          <td>
                            <div
                              className="mr-10 mouse-cursor icon-flex tooltip"
                              onClick={(e) => this.onShowDetail(item)}
                            >
                              <img
                                className="icon-size-view"
                                src={viewIcon}
                                alt=""
                              />
                              <span className="tooltiptext">
                                {t("view_detailed_info")}
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              )}
            </table>
          </div>
          {/* Detail Show*/}
          {this.state.detail_show && (
            <>
              <div style={{ marginTop: 70 }}>
                <div className="pt-20 pb-20 justify-center col-selected-bg txt-20">
                  {t("detailed_view_track")} "{this.state.detail_item.name}"
                </div>
              </div>
              <div className="mt-30 justify-center col-darkBlue txt-bold txt-20">
                {t("track_history")}
              </div>
              <div className="table-p">
                <table className="tList">
                  <thead className="track-list">
                    <tr className="table-list album">
                      <th>{t("Num")}</th>
                      <th>{t("country")}</th>
                      <th>{t("platform")}</th>
                      <th>{t("price_per_played_track")}</th>
                      <th>{t("played_date")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.detail_item.details.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td style={{ paddingLeft: 20 }}>{key + 1}</td>
                          <td>{item.country}</td>
                          <td>{item.platform ? item.platform : ""}</td>
                          <td>
                            {(item.price_per_track ? item.price_per_track : 0) +
                              (this.state.currency
                                ? this.state.currency
                                : config.SYMBOL[0])}
                          </td>
                          <td>
                            {new Date(item.played_date).toLocaleString([], {
                              year: "numeric",
                              month: "short",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/*  CSV & Print Download  */}
              <div className="flex-space mt-30">
                <div
                  className="btn-common action assign mouse-cursor col-white"
                  onClick={() => window.print("")}
                >
                  {t("print")}
                </div>
                <div
                  className="btn-common action assign mouse-cursor col-white"
                  onClick={() => this.exportTableToCSV("members.csv")}
                >
                  {t("csv")}
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinning: state.users.spinning,
    get_track_list: state.users.get_track_list,
    msg_error_tracks: state.users.msg_error_tracks,
  };
};
export default connect(mapStateToProps, {
  reset,
  getTrackListById,
})(withTranslation("translations")(UserTrackList));
