import React, {Component} from 'react';
import '../assets/css/dashboard.css';
import {connect} from "react-redux";
import {
    reset,
    sendEmail
} from "../redux/actions/users/publisher";
import {toast} from "react-toastify";
import { withTranslation } from "react-i18next";
class SendEmail extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.msg_send_email && prevProps.msg_send_email !== this.props.msg_send_email) {
            toast(this.props.msg_send_email);
            const {
                reset,
                handleClose,
            } = this.props;
            clearTimeout(this.tmr);
            this.tmr = setTimeout(function () {
                reset();
                this.tmr = null;

                handleClose();
                window.location.href = "/user-list";
            }, 2000);
        }
    }
    Cancel = () => {
        const {
            handleClose
        } = this.props;

        handleClose();
    };
    onSend = (_id,language) => {
        const data = {
            role_id: localStorage.id,
            id: _id,
            language:language
        };
        const {
            sendEmail
        } = this.props;
        sendEmail(data);
    };
    render() {
        const { t } = this.props;
        const showHideClassName = this.props.show ? "modal-b display-modal-block" : "modal-b display-modal-none";
        return (
            <div className={showHideClassName}>
                <section className="modal-article">
                    <div className="create-modal-header txt-20 justify-left col-white">{t("send_email")}</div>
                    <div className="pt-30 pb-30 txt-18 justify-center">{t("language")}</div>
                    <div className="flex-grid3 modal-grid3-gaps modal-p">
                        <div className="btn-common mouse-cursor justify-center col-white" onClick={this.Cancel}>{t("cancel")}</div>
                        <div className="btn-common mouse-cursor justify-center col-white" onClick={() => this.onSend(this.props.id,1)}>{t("english")}</div>
                        <div className="btn-common mouse-cursor justify-center col-white" onClick={() => this.onSend(this.props.id,2)}>{t("hebrew")}</div>
                    </div>
                </section>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        msg_send_email: state.users.msg_send_email,
    }
};
export default connect(
    mapStateToProps,
    {
        reset,
        sendEmail,
    }
)(withTranslation("translations")(SendEmail));

