import React from 'react';
import {Provider} from 'react-redux';
import ReactDOM from 'react-dom';
import '../src/assets/css/index.css';
import {store} from "./redux/storeConfig/store";
import App from './app';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from "react-i18next";
import i18n from "./config/i18n";

ReactDOM.render(
    <I18nextProvider i18n={ i18n }>
        <Provider store={ store }>
            <App />
        </Provider>
    </I18nextProvider>,
    document.getElementById('root'));
serviceWorker.unregister();