import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const Button = ({ title, onClick, style, className }) => (
  <button style={style} onClick={onClick} className={className}>
    {title}
  </button>
);

export default ({
  trigger,
  title,
  message,
  buttons,
  containerStyle,
  buttonsContainerStyle,
}) => (
  <Popup trigger={trigger} modal>
    {(close) => (
      <div
        style={{
          padding: 10,
          zIndex: 200,
          display: "flex",
          flexDirection: "column",
          ...containerStyle,
        }}
      >
        <h1>{title}</h1>
        <p>{message}</p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 30,
            ...buttonsContainerStyle,
          }}
        >
          {buttons.map((button, index) => (
            <Button
              key={index}
              {...button}
              onClick={() => {
                button.onClick();
                close();
              }}
            />
          ))}
        </div>
      </div>
    )}
  </Popup>
);
